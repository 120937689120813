import React from "react"
import './index.css'
import './app/App.css'
import { RouteSelector } from '../components/RouteSelector'

const IndexPage = () => {

  return(
    <RouteSelector/>
      )
}


export default IndexPage